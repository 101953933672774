import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {HelperService} from "../../services/helper.service";
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

@Component({
  selector: 'dg-sales-incentives',
  templateUrl: './sales-incentives.component.html',
})
export class SalesIncentivesComponent implements OnInit {
  @ViewChild('videoModal', { static: true }) public videoModal: TemplateRef<any>;
  videoModalRef: BsModalRef;


  constructor(
    public helperService: HelperService,
    private modalService: BsModalService,
  ) { }

  ngOnInit() {
  }

  openVideoModal(event: MouseEvent) {
    event.preventDefault();
    this.videoModalRef = this.modalService.show(this.videoModal, { class: 'last-call-video'});
    this.helperService.trackGAEvent('Homepage', 'Click', 'Sales Incentives - Last Call - Open');
  }

  closeVideoModal() {
    this.videoModalRef.hide();
    this.helperService.trackGAEvent('Homepage', 'Click', 'Sales Incentives - Last Call - Close');
  }

}
