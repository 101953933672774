<div style="position: relative;">
    <section id="horsepower-locator-tile">
        <div class="hero-container fade-in">
            <div class="horsepower-logo">
                <img src="/assets/images/hpl-logo-white-base.webp" alt="Dodge Horsepower Locator - Logo" class="img-responsive" width="768" height="413">


            </div>
            <div class="horsepower-content">
                <p id="dont-say-warning-text" class="text-center text-white text-uppercase">
                      <span class="combo">
                        CHARGER DAYTONA 2-DOOR
                      </span>
                    <span>+</span>
                    <span>DURANGO SPECIAL EDITIONS</span>
                </p>
                <a routerLink="/horsepower-locator" id="hpl-breakout-link" class="btn btn-primary" (click)="sendGTMEvent()">Find your ride</a>
            </div>
            <div class="incentives-copy">
                <p>
                    LEASE A 2024 DODGE CHARGER DAYTONA R/T FOR $611 PER MONTH FOR 36 MONTHS.
                    <span class="v-adjust" (click)="openTooltipModal(1)">1</span>
                </p>
                <p>$5,239 DUE AT SIGNING. NO SECURITY DEPOSIT REQUIRED.</p>
                <p>
                    Lease payment term has factored in a $7,500 EV Incentive, provided by lender.
                    <span class="v-adjust" (click)="openTooltipModal(2)">2</span>
                </p>
            </div>
        </div>
    </section>
</div>

<ng-template #tooltipModal>
    <div class="modal-container modal-container-lg">
        <div class="modal-header">
            <button class="modal-close" (click)="tooltipModalRef.hide()"><i class="fa fa-close"><span class="sr-only">close</span></i></button>
        </div>
        <div class="modal-body">
            <p class="fs-legal" *ngIf="activeTooltip === 1">
                Low-mileage lease for well-qualified lessees. Based on MSRP example of $66,585 with 25M package after all applicable offers. Total due at lease signing includes a downpayment and first month's payment. Offer requires dealer contribution. Tax, title & license extra. Lessee is solely responsible for early lease termination payments, fees, costs, penalties, excess wear and tear and mileage of $0.30/mile for each mile over 10,000 miles per year, if vehicle is returned at end of term. $395 disposition fee due at lease end. Dealer's actual terms may vary. Offer through Stellantis Financial Services. Residency restrictions apply. Contact dealer for details. Offer ends 02/03/2025.
            </p>
            <p class="fs-legal" *ngIf="activeTooltip === 2">
                When leased through Stellantis Financial Services, not all lessees will qualify. This incentive is offered by a third party as a cap cost reduction and subject to change without notice. Lessees cannot claim tax credit on personal tax return. Consult a tax professional for details and eligibility requirements. Restrictions apply.
            </p>
        </div>
    </div>
</ng-template>
