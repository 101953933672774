<section *ngIf="comingSoon" id="coming-soon" class="bg-black">
  <div class="site-wrapper-xl container-fluid w-100">
    <div class="row">
      <div class="col-sm-12">
        <div class="coming-soon-content">
          <div class="logo">
            <img src="/assets/images/hpl-logo-white-base.webp" alt="Dodge Horsepower Locator - Logo" class="img-responsive">
          </div>
          <div class="details">
            <img src="/assets/images/coming-soon.webp" alt="Coming Soon" class="img-responsive">
            <span>
              Last Call 2024 Durango<br>Special Editions + Trims
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<div *ngIf="!comingSoon" id="horsepower-locator" class="bg-black">
  <a class="skip-main" href="#main">Skip to main content</a>
  <section id="hero-area">
    <div class="site-wrapper-xl container-fluid w-100">
      <div class="row">
        <div class="col-md-6 hero-img-container">
          <div class="logo">
            <img src="/assets/images/hpl-logo-white-base.webp" alt="Dodge Horsepower Locator" class="img-responsive">
            <div class="incentives-copy">
              <p>
                LEASE A 2024 DODGE CHARGER DAYTONA R/T FOR $611 PER MONTH FOR 36 MONTHS.
                <span class="v-adjust" (click)="openTooltipModal(1)">1</span>
              </p>
              <p>$5,239 DUE AT SIGNING. NO SECURITY DEPOSIT REQUIRED.</p>
              <p>
                Lease payment term has factored in a $7,500 EV Incentive, provided by lender.
                <span class="v-adjust" (click)="openTooltipModal(2)">2</span>
              </p>
              <p>
                0% APR financing for 72 months on 2024 Dodge Charger Daytona models.
                <span class="v-adjust" (click)="openTooltipModal(3)">3</span>
              </p>
            </div>
          </div>
          <div *ngIf="isVideoVisible" class="embed w-100">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/hlGP0jbSlZ8"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen></iframe>
          </div>
        </div>
        <div class="col-md-6">
          <div id="header-copy-search">
            <h1 class="hero-heading">CALLING ALL HORSEPOWER FANATICS</h1>

            <p id="main" tabindex="-1">
              The Charger Daytonas and Durango Special Editions included here are either the first of their kind or limited production, so our goal is to help you find the exact vehicle you're looking for by connecting you with dealers who have the goods.
            </p>
            <hpl-search-tips></hpl-search-tips>
            <form [formGroup]="initialSearchForm" (ngSubmit)="onInitialSearch(maxResults, '0')" id="initialSearchForm"
                  [ngClass]="{'hidden': isInitialSearchCompleted }">
              <div class="form-group">
                <label for="zipcode" class="control-label">ZIP Code</label>
                <div class="right-inner-addon">

                  <input formControlName="initialZipcode" class="form-control" type="text" name="initialZipcode" aria-label="input zipcode" autofocus
                         id="initialZipCode" placeholder="99999" minlength="5" maxlength="5" pattern="\d*" required [ngClass]="{invalid: initialSearchForm.controls['initialZipcode'].errors && initialSearchForm.controls['initialZipcode'].errors.pattern}">
                </div>
                <span *ngIf="(initialSearchForm.controls['initialZipcode'].errors && initialSearchForm.controls['initialZipcode'].errors.pattern)" class="visible error">Numbers only.</span>
                <span *ngIf="(initialSearchForm.controls['initialZipcode'].errors && initialSearchForm.controls['initialZipcode'].errors.invalid)" class="visible error">That's not a real zip code, and we both know it. Try again.</span>
              </div>
              <div class="model-select-controls">
                <div class="form-group" id="vehicle-select-group">
                  <label class="control-label" for="initial-vehicle">Select a model to start</label>
                  <span id="initial-vehicle">
                    <label *ngIf="availableCharger" class="radio-inline">
                      <input type="radio" formControlName="initialModel" name="initialModel" value="Charger Daytona 2-Door" aria-label="Select Charger Daytona model" required> Charger Daytona 2-Door
                      <div class="custom-radio"></div>
                    </label>
                    <label *ngIf="availableDurango" class="radio-inline">
                      <input type="radio" formControlName="initialModel" name="initialModel" value="Durango" aria-label="Select Durango model" required> Durango
                      <div class="custom-radio"></div>
                    </label>
                  </span>
                </div>
                <button [disabled]="!initialSearchForm.valid" id="initial-search-button" type="submit" class="btn btn-primary" aria-label="Search for closest results to your zipcode">Search</button>
              </div>
              <div id="unavailable-error" class="alert alert-banner bg-danger" *ngIf="(initialSearchForm.controls['initialZipcode'].errors && initialSearchForm.controls['initialZipcode'].errors.unavailable)">
                <div class="row">
                  <div class="col-sm-12 flex-container">
                    <div class="context-icon">
                      <i aria-hidden="true" class="fa fa-exclamation-triangle"></i>
                    </div>
                    <div class="context-message">
                      <h4>We're staging for the next round of action.</h4>
                      <p>Check back soon!</p>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <!--            <div id="canadian-d-170-availability">-->
            <!--              <p style="color: #FF0000;">2023 Challenger SRT<sup>®</sup> Demon 170 ordering is closed.</p>-->
            <!--            </div>-->
            <div id="durango-availability">
              <p>Looking for something else? <a (click)="trackDealerInventoryLink()" href="https://www.dodge.com/vehicle-selector.sni.html?app=sni" target="_blank"><strong>Search new dealer inventory on Dodge.com</strong></a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<div *ngIf="!isInitialSearchCompleted && !isSearchStarted && !errorMsg" class="start-searching">
  <div class="site-wrapper">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12 text-center">
          <h2>Start searching now</h2>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!errorMsg" >
  <div *ngIf="isSearchStarted" id="results-and-filter">
    <div class="site-wrapper">
      <div class="container-fluid">
        <div class="row d-flex-md">
          <div class="filters-column col-md-3 col-lg-4">
            <button class="btn btn-primary btn-accordion" type="button" data-toggle="collapse"
              data-target="#filters-collapse" aria-expanded="false">
              Filters <i class="fa fa-caret-down"></i>
            </button>
            <div id="filters-collapse" class="collapse">
              <hpl-search-filters *ngIf="isInitialSearchCompleted"
                (newTrimSelected)="onTrimSelected($event, maxResults, '0')"
                (newModelSelected)="onModelSelected($event, maxResults, '0')"
                (newAreaSearch)="onNewAreaSearch($event, maxResults, '0')"
                (configIdSelected)="onUpdatedConfigId($event)"
                [selectedModel]="model"
                [zipcode]="zipcode"
                [availableCharger]="availableCharger"
                [availableDurango]="availableDurango">
              </hpl-search-filters>
            </div>
          </div>
          <div class="results-column col-md-9 col-lg-8">
            <div class="print-header">
              <div>
                <span>Showing Results For:</span>
                <span class="lg text-bold">{{ model }} <span
                    [innerHTML]="getTrimLabel(model, currentFormData.trimName)"></span></span>
              </div>
              <div>
                <span><span class="text-bold">{{ availableOrders.toLocaleString('en') }}</span> RESULTS BY CLOSEST
                  DEALER</span>
              </div>
            </div>
            <div id="dealer-card-container" #dealerCardContainer *ngIf="showResults; else loading">
              <div id="results-group" class="container-header">
                <div id="results-download">
                  <div>
                    <div id="total-results-number"><span>{{ availableOrders.toLocaleString('en') }}</span> RESULTS BY
                      CLOSEST DEALER</div>
                    <div id="show-power-brokers-checkbox">
                      <input type="checkbox" (change)="onShowPowerBrokers(maxResults, '0')"
                        [checked]="showOnlyPowerBrokers" name="show-power-brokers" id="show-power-brokers">
                      <label for="show-power-brokers">
                        View Only Power Brokers Dealers
                        <img class="small-power-broker-logo" src="/assets/images/logos/power-brokers-logo-sm.png"
                          alt="Dodge Power Brokers Logo">
                      </label>
                    </div>
                  </div>
                  <div>
                    <button *ngIf="isTrimSelected" id="top-100-results" class="btn btn-dark download-results" (click)="printResults()"><i
                        class="fa fa-file-pdf-o"></i> Download the top 100 results</button>
                    <div id="data-last-updated">Data was last updated on: {{ lastUpdatedDate }}</div>
                  </div>
                </div>
              </div>
              <div class="card-container-inner" *ngIf="results.length > 0; else noResults">
                <hpl-dealer-card *ngFor="let result of results; let i = index" [quantity]="result.totalQuantity"
                  [model]="model" [dealerName]="result.name" [address]="result.address" [city]="result.city"
                  [state]="result.state" [postalCode]="result.postalCode" [phoneNumber]="result.phoneNumber"
                  [dealercode]="result.dealercode" [distance]="result.distance" [trimLink]="trimLink" [trimLinkText]="trimLinkText"
                  [modelLink]="modelLink" [trimType]="trimType" [website]="result.website"
                  [isPowerBroker]="result.powerBroker" [configId]="configId" [currentFormData]="currentFormData" class="hpl-card" [class.show]="i <= cardCounter">
                </hpl-dealer-card>
              </div>
              <ng-template #noResults>
                <div class="no-results-container">
                  <p>There are no results for the choices you selected.</p>
                </div>
              </ng-template>
            </div>
            <div class="print-footer">
              <div class="img-container">
                <img class="footer-img" src="/assets/images/hpl-logo-black-base.webp"
                  alt="Horsepower Locator - Don't Say we didn't warn you video">
              </div>
              <div class="content">
                &copy;2024 FCA US LLC. All Rights Reserved. Chrysler, Dodge, Jeep, Ram, Wagoneer, Mopar, SRT and HEMI
                are registered trademarks of FCA US LLC. ALFA ROMEO and FIAT are registered trademarks of FCA Group
                Marketing S.p.A., used under license by FCA US LLC.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="loading-area">
    <div class="loading-icon">
      <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>

<ng-template #videoModal>
  <div class="modal-container">
    <div class="modal-header">
      <button class="modal-close" (click)="closeVideoModal()"><i class="fa fa-close"><span class="sr-only">close</span></i></button>
    </div>
    <div class="modal-body site-wrapper">
      <div class="embed-responsive embed-responsive-16by9">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/EUs0D9fZFWA" title="Horsepower Locator" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #errorBlock>
  <div class="site-wrapper">
    <div class="container">
      <div class="row">
        <div class="col">
          <p class="error-message-large" [innerHTML]="errorMsg"></p>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #disclaimerModal>
  <div class="modal-container modal-container-lg">
    <div class="modal-header">
      <button class="modal-close" (click)="disclaimerModalRef.hide()"><i class="fa fa-close"><span class="sr-only">close</span></i></button>
    </div>
    <div class="modal-body">
      <!-- <p class="modal-heading">Dodge Badassadors Roles & Responsibilities:</p> -->
      <p class="mb-0">
          These results include both pre-configured vehicles and orders dealers placed that are (to the best of our knowledge) still up for grabs. For non-pre-configured vehicles, please contact dealers directly for specifications and pricing.
      </p>
    </div>
  </div>
</ng-template>

<ng-template #tooltipModal>
  <div class="modal-container modal-container-lg">
    <div class="modal-header">
      <button class="modal-close" (click)="tooltipModalRef.hide()"><i class="fa fa-close"><span class="sr-only">close</span></i></button>
    </div>
    <div class="modal-body">
      <p class="fs-legal" *ngIf="activeTooltip === 1">
        Low-mileage lease for well-qualified lessees. Based on MSRP example of $66,585 with 25M package after all applicable offers. Total due at lease signing includes a downpayment and first month's payment. Offer requires dealer contribution. Tax, title & license extra. Lessee is solely responsible for early lease termination payments, fees, costs, penalties, excess wear and tear and mileage of $0.30/mile for each mile over 10,000 miles per year, if vehicle is returned at end of term. $395 disposition fee due at lease end. Dealer's actual terms may vary. Offer through Stellantis Financial Services. Residency restrictions apply. Contact dealer for details. Offer ends 02/03/2025.
      </p>
      <p class="fs-legal" *ngIf="activeTooltip === 2">
        When leased through Stellantis Financial Services, not all lessees will qualify. This incentive is offered by a third party as a cap cost reduction and subject to change without notice. Lessees cannot claim tax credit on personal tax return. Consult a tax professional for details and eligibility requirements. Restrictions apply.
      </p>
      <p class="fs-legal" *ngIf="activeTooltip === 3">
        0% APR financing for 72 months equals $13.89 per month per $1,000 financed for well-qualified buyers through Stellantis Financial Services regardless of down payment. Not all buyers will qualify. Not compatible with any other incentive programs or offers. Residency restrictions apply. Contact dealer for details. Must take delivery by 02/03/2025.
      </p>
    </div>
  </div>
</ng-template>
