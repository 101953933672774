import { NewsModalGuard } from './news-articles/news-modal.guard';
import { TrackLocatorService } from './racing-hq/track-locator/track-locator.service';
import { JuicerService } from './juicer/juicer.service';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from "@angular/common/http";


import { ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { SafePipe } from './services/safeUrl.pipe';
import { KebabPipe } from './services/kebab.pipe';

// Bootstrap
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { NgxMasonryModule } from 'ngx-masonry';

// App Services
import { ChallengerService } from './challenger/challenger.service';
import { EventService } from './event/event.service';
import { UserService } from './user/user.service';
import { HelperService } from './services/helper.service';
import { CanActivateViaAuthGuard } from './services/authGuard.service';
import { GalleryService } from "./gallery/gallery.service";

import { TimeSlipService } from './dg-1320/time-slip/time-slip.service';
import { TimeSlipHelper } from './dg-1320/time-slip/time-slip';
import { BadgeService} from "./dg-1320/badge/badge.service";

//Gallery Components
import { GalleryComponent } from './gallery/gallery.component';

// Navigation and User
import { Navigation } from './layouts/navigation/navigation.component';

// Banners
import { ActivationBannerComponent } from './banners/activation-banner/activation-banner.component';
import { SocialBannerComponent } from './banners/social-banner/social-banner.component';
import { PasswordResetBannerComponent } from './banners/password-reset-banner/password-reset-banner.component';
import { LivestreamBannerComponent } from './banners/livestream-banner/livestream-banner.component';
import { SalesIncentivesComponent } from './banners/sales-incentives/sales-incentives.component';
import { Dg1320StandingsComponent } from './banners/dg-1320-standings/dg-1320-standings.component';
import { HorsepowerChallengeComponent } from './banners/horsepower-challenge/horsepower-challenge.component';
import { BracketBannerComponent } from './banners/bracket-banner/bracket-banner.component';
import { DodgeRevealLivetreamBannerComponent } from './banners/dodge-reveal-livetream-banner/dodge-reveal-livetream-banner.component';
import { CarShowBannerComponent } from './banners/car-show-banner/car-show-banner.component';
import { DodgeNeverliftBannerComponent } from './banners/dodge-neverlift-banner/dodge-neverlift-banner.component';
import { MissionBannerComponent } from './banners/mission-banner/mission-banner.component';

// Homepage hero
import  { HeroGarageTeaserComponent } from './components/homepage-heroes/hero-garage-teaser/hero-garage-teaser.component';

import { HomepageComponent } from './homepage/homepage.component'
import { DownloadsComponent } from './downloads/downloads.component';
import { HpdsComponent } from './hpds/hpds.component';
// import { DragRacingComponent } from './hpds/drag-racing/drag-racing.component';
import { FaqComponent } from './hpds/faq/faq.component';
import { AvatarComponent } from './user/avatar/avatar.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { TrademarksComponent } from './trademarks/trademarks.component';
import { LoginFormInlineComponent } from './user/forms/login-form-inline/login-form-inline.component';
import { HeroPanelComponent } from './homepage/hero-panel/hero-panel.component';
import { ContestService } from './contest/contest.service';
import { BarrettJacksonComponent } from './contest/barrett-jackson/barrett-jackson.component';
import { ChallengerListPageComponent} from './challenger/challenger-list-page/challenger-list-page.component';
import { EventSinglePageComponent } from './event/event-single-page/event-single-page.component';
import { BarrettJacksonRulesComponent } from './contest/barrett-jackson/barrett-jackson-rules/barrett-jackson-rules.component';
import { LivestreamComponent } from './livestream/livestream.component';
import { DemonFaqComponent } from './demon-faq/demon-faq.component';
import { ContributorsComponent } from './contributors/contributors.component';
import { ContributorsService } from './contributors/contributors.service';
import { EventDodgeOfficialService } from './event/event-dodge-official/event-dodge-official.service';
import { RingtonesComponent } from './downloads/ringtones/ringtones.component';
import { RingtoneInstructionsComponent } from './downloads/ringtone-instructions/ringtone-instructions.component';
import { LastChanceComponent } from './last-chance/last-chance.component';
import { ImgCarouselComponent } from './last-chance/img-carousel/img-carousel.component';
import { AudioPlayerComponent } from './audio/audio-player/audio-player.component';
import { ActivationNoticeComponent } from './user/activation-notice/activation-notice.component';
import { PodcastService } from "./podcast/podcast.service";
import { PodcastListPageComponent } from './podcast/podcast-list-page/podcast-list-page.component';
import { NewsArticleService } from "./news-articles/news-article.service";
import { FormatTimePipe } from "./services/format-time.pipe";
import { RaceService } from "./race/race.service";
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { UserInterceptor } from './user/user.interceptor';
import { PowerbrokersCrateEngineComponent } from './components/homepage-heroes/powerbrokers-crate-engine/powerbrokers-crate-engine.component';


//Brag Book
import { AuctionBragBookComponent } from './auction-brag-book/auction-brag-book.component';
import { LastChanceAuctionBookFullComponent } from './last-chance-auction-book-full/last-chance-auction-book-full.component';
import { DownloadsService } from './downloads/downloads.service';
import { GalleryServiceService } from './last-chance/gallery-service.service';
import { DragStripShowdownComponent } from './contest/drag-strip-showdown/drag-strip-showdown.component';
import { RulesComponent } from './contest/drag-strip-showdown/rules/rules.component';
import { LeaderboardComponent } from './contest/drag-strip-showdown/leaderboard/leaderboard.component';
import { DragStripShowdownHeroComponent } from './contest/drag-strip-showdown/drag-strip-showdown-hero/drag-strip-showdown-hero.component';
import { ScheduleComponent } from './livestream/schedule/schedule.component';
import { LivestreamEmbedComponent } from './livestream/livestream-embed/livestream-embed.component';
import { NationalLeaderboardPageComponent } from './contest/drag-strip-showdown/national-leaderboard-page/national-leaderboard-page.component';
import { PaginationComponent } from './shared/pagination/pagination.component';
import { EventListLeaderboardsComponent } from './contest/drag-strip-showdown/event-list-leaderboards/event-list-leaderboards.component';
import { EventSingleLeaderboardComponent } from './contest/drag-strip-showdown/event-single-leaderboard/event-single-leaderboard.component';
import { StencilsComponent } from './downloads/stencils/stencils.component';

//1320 Components
import { TimeSlipSubmissions } from './dg-1320/time-slip/time-slip-submissions/time-slip-submissions.component';
import {VehicleService} from "./vehicle/vehicle.service";
import { UserProfileComponent } from './user/user-profile/user-profile.component';
import {UserProfileResolverService} from "./user/user-profile/user-profile-resolver.service";
import { BadgeComponent } from "./dg-1320/badge/badge.component";
import { RecentArticlesComponent } from './news-articles/recent-articles/recent-articles.component';
import { CommentService } from './comment/comment.service';
import { ManageAccountComponent } from './user/manage-account/manage-account.component';
import { AuthGuard } from './user/auth.guard';
import { StyleGuideComponent } from './style-guide/style-guide.component';
import { StyleGuideFormComponent } from './style-guide/style-guide-form/style-guide-form.component';
import { StyleGuideGalleryComponent } from './style-guide/style-guide-gallery/style-guide-gallery.component';
import { HolidayCardsComponent } from './downloads/holiday-cards/holiday-cards.component';
import {Challenger1320Component} from "./owner-perks/1320/1320.component";
import { DeactivateAccountComponent } from './user/deactivate-account/deactivate-account.component';
import { MetamorphosisLegoDodgeComponent } from './homepage/spotlight/metamorphosis-lego-dodge/metamorphosis-lego-dodge.component';
import { SpotlightDefaultComponent } from './homepage/spotlight/spotlight-default/spotlight-default.component';
import { SurveyComponent } from './survey/survey.component';
import { PollComponent } from './polls/poll/poll.component';
import {PollService} from "./polls/poll.service";
import { TrackLocatorComponent, TrackInfoWindowComponent } from './racing-hq/track-locator/track-locator.component';
import { JuicerComponent } from './juicer/juicer.component';
import { TimeSincePipe } from './shared/pipes/time-since.pipe';
import { NewsArticleSinglePageComponent } from './news-articles/news-article-single-page/news-article-single-page.component';
import { GalleryCardComponent } from './gallery/gallery-card/gallery-card.component';
import { GalleryOpenCardComponent } from './gallery/gallery-open-card/gallery-open-card.component';
import { GallerySearchComponent } from './gallery/gallery-search/gallery-search.component';
import { DeferLoadModule } from '@trademe/ng-defer-load';

import { BondurantModalComponent } from './hpds/bondurant-modal/bondurant-modal.component';
import { VideoPageComponent } from './video/video-page/video-page.component';
import { RacingHqCtaComponent } from './cta-tiles/racing-hq-cta/racing-hq-cta.component';
import { DownloadsCtaComponent } from './cta-tiles/downloads-cta/downloads-cta.component';
import { BondurantCtaComponent } from './cta-tiles/bondurant-cta/bondurant-cta.component';
import { MerchCtaComponent } from './cta-tiles/merch-cta/merch-cta.component';
import { MuscleBehindUsCtaComponent } from './cta-tiles/muscle-behind-us-cta/muscle-behind-us-cta.component';
import { NewsArticleSharedModule } from './news-articles/news-article-shared.module';
import { DodgeOfficialCtaComponent } from './cta-tiles/dodge-official-cta/dodge-official-cta.component';
import { CtaTilesSharedModule } from './cta-tiles/cta-tiles-shared.module';
import { VideoSharedModule } from './video/video-shared.module';
import { SharedModule } from './shared/shared.module';
import { EventSharedModule } from './event/event-shared.module';
import { ThirteenTwentySharedModule } from './dg-1320/thirteen-twenty-shared.module';
import { UserSharedModule } from './user/user-shared.module';
import { CommentSharedModule } from './comment/comment-shared.module';
import { DealerLocatorService } from './services/dealer-locator.service';
import { ThirteenTwentyCtaComponent } from './cta-tiles/thirteen-twenty-cta/thirteen-twenty-cta.component';
import { DirectConnectionCta } from './cta-tiles/direct-connection-cta/direct-connection-cta.component';

// Drag Pak Video
// import { VideoDragPakComponent } from './shared/video-drag-pak/video-drag-pak.component';

//Schema/JSON+LD Module
import { NgxJsonLdModule} from "@ngx-lite/json-ld";

// Virtual Car Show
// import { VirtualCarShowComponent } from "./virtual-car-show/virtual-car-show.component";
// import {VirtualCarShowSingleComponent} from "./virtual-car-show/virtual-car-show-single/virtual-car-show-single.component";
// import {VirtualCarShowListComponent} from "./virtual-car-show/virtual-car-show-list/virtual-car-show-list.component";
// import {VirtualCarShowService} from "./virtual-car-show/virtual-car-show.service";
// import {VirtualCarShowResolverService} from "./virtual-car-show/virtual-car-show-resolver.service";
// import {VirtualCarShowService} from "./virtual-car-show/virtual-car-show.service";

// Drag Pak Experience
import {DragPakExperienceComponent} from "./drag-pak-experience/drag-pak-experience.component";
import {DragPakLandingPageComponent} from "./drag-pak-experience/page-components/drag-pak/drag-pak.component";
import {DragPakHistoryComponent} from "./drag-pak-experience/page-components/drag-pak-history/drag-pak-history.component";
import {DragPakBuildOverviewComponent} from "./drag-pak-experience/page-components/build-overview/build-overview.component";
import {DragPakStartupComponent} from "./drag-pak-experience/page-components/startup-procedures/startup-procedures.component";
import {DragPakCompetitionSetupComponent} from "./drag-pak-experience/page-components/competition-setup/competition-setup.component";
import {DragPakPreRunChecklistComponent} from "./drag-pak-experience/page-components/pre-run-checklist/pre-run-checklist.component";
import {DragPakInitialTrackTestComponent} from "./drag-pak-experience/page-components/initial-track-test/initial-track-test.component";
import {DragPakECULaunchSettingComponent} from "./drag-pak-experience/page-components/ecu-launch-setting/ecu-launch-setting.component";
import {DragPakPostRunChecklistComponent} from "./drag-pak-experience/page-components/post-run-checklist/post-run-checklist.component";

import { NeverLiftComponent } from "./never-lift/never-lift.component";
import { CountdownModule } from 'ngx-countdown';

// Advent Calendar Components
import { GarageTeaserTileComponent } from "./homepage/sections/garage-teaser-tile/garage-teaser-tile.component";

// Chief Donut Maker
// import { ChiefDonutMakerComponent } from "./chief-donut-maker/chief-donut-maker.component";
// import { CDMLandingPageComponent } from "./chief-donut-maker/pages/cdm-landing-page/cdm-landing-page.component";
// import { CDMJobPageComponent } from "./chief-donut-maker/pages/cdm-job-page/cdm-job-page.component";
// import { CDMPerksPageComponent } from "./chief-donut-maker/pages/cdm-perks-page/cdm-perks-page.component";
// import { CDMApplicationPageComponent } from "./chief-donut-maker/pages/cdm-application-page/cdm-application-page.component";
// import { CDMApplicationStatusPageComponent } from "./chief-donut-maker/pages/cdm-application-status-page/cdm-application-status-page.component";
// import { CDMExperiencePageComponent } from "./chief-donut-maker/pages/cdm-experience-page/cdm-experience-page.component";
// import { CDMApplicationComponent} from "./chief-donut-maker/cdm-application/cdm-application.component";
// import { CDMHiringProcessComponent } from "./chief-donut-maker/pages/cdm-hiring-process-page/cdm-hiring-process-page.component";
// import { CDMAnnouncementTileComponent } from "./homepage/sections/cdm-announcement-tile/cdm-announcement-tile.component";
// import { CDMFinalistCardComponent } from "./chief-donut-maker/cdm-finalist-card/cdm-finalist-card.component";
// import { CDMEliminationEpisodeCardComponent } from "./chief-donut-maker/cdm-elimination-episode-card/cdm-elimination-episode-card";

import { EventsSlider } from "./components/events-slider/events-slider.component";
import { FeatureSlider } from "./components/feature-slider/feature-slider.component";
import { VideoTabsComponent } from "./components/videos-section/video-tabs/video-tabs.component";
import { VideosSection } from "./components/videos-section/videos-section.component";

// import { HeroCDMComponent } from "./components/homepage-heroes/hero-cdm/hero-cdm.component";

// Swiper
import { SwiperModule } from 'swiper/angular';
// import {CDMRulesPageComponent} from "./chief-donut-maker/pages/cdm-rules-page/cdm-rules-page.component";

// cookies
import { CookieService } from 'ngx-cookie-service';

// Event Redirects
import { EventRegistrationRedirectComponent } from './event-registration-redirect/event-registration-redirect.component';
import { NeverLiftCountdownComponent } from './components/homepage-heroes/never-lift-countdown/never-lift-countdown.component';

// Highway 93
// import { HighwayNinetyThreeComponent } from './highway-ninety-three/highway-ninety-three.component';
// import { HighwayLocationComponent } from './highway-ninety-three/highway-location/highway-location.component';
// import { HighwayInfoWindowComponent, HighwayMapComponent } from './highway-ninety-three/highway-map/highway-map.component';
// import { HighwaySocialComponent } from './highway-ninety-three/highway-social/highway-social.component';
// import { HighwayFilterComponent } from './highway-ninety-three/highway-filter/highway-filter.component';
// import { HighwayLocationsService } from './highway-ninety-three/highway-locations.service';
// import { HighwayLocationListComponent } from './highway-ninety-three/highway-location-list/highway-location-list.component';
// import { HighwayHeroComponent } from './components/homepage-heroes/highway-hero/highway-hero.component';
// import { HighwayJuicerComponent } from './highway-ninety-three/highway-juicer/highway-juicer.component';

// RKN
import { RknLandingPageComponent } from './rkn/rkn-landing-page.component';
import { RKN2023TeaserComponent } from './components/homepage-heroes/rkn-2023-teaser/rkn-2023-teaser.component';
import { RknSocialIcons } from './rkn/social-icons/social-icons.component';

// RKN 2021
import { RoadkillNightsVideo } from "./roadkill-nights-video/roadkill-nights-video.component";
import { Rkn2021LandingPageComponent } from './rkn2021/rkn-landing-page/rkn-landing-page.component';
import { ContributorOneComponent } from './rkn2021/contributors/contributor-one/contributor-one.component';
import { ContributorTwoComponent } from './rkn2021/contributors/contributor-two/contributor-two.component';
import { ContributorThreeComponent } from './rkn2021/contributors/contributor-three/contributor-three.component';
import { ContributorFourComponent } from './rkn2021/contributors/contributor-four/contributor-four.component';
import { ContributorFiveComponent } from './rkn2021/contributors/contributor-five/contributor-five.component';
import { YoutubeFeedComponent } from './rkn2021/components/youtube-feed/youtube-feed.component';
import { ImageFeedComponent } from './rkn2021/components/image-feed/image-feed.component';
import { RknModule } from "./rkn2021/rkn.module";

// RKN 2022
import { RKN2022LandingPageComponent } from './rkn2022/rkn-landing-page/rkn-2022-landing-page.component';
import { RKN2022ContributorsComponent } from './rkn2022/rkn-contributors/rkn-2022-contributors.component';
// import { Hwy93BannerComponent } from './components/banners/hwy93-banner/hwy93-banner.component';
import { RKNLivestreamCountdownComponent } from './components/homepage-heroes/rkn-livestream-countdown/rkn-livestream-countdown.component';

// RKN 2023
import { Rkn2023LandingPageComponent } from './rkn2023/rkn-landing-page.component';

// SRT Experience
import { SRTRegistrationComponent } from './srt/srt-registration.component';

// Iframe Directive
import { IframeAutoHeightDirective } from './srt/iframe-auto-height-directive.directive';

// Challenger Drop Top
// import { ChallengerDropTopComponent } from './challenger-drop-top/challenger-drop-top.component';
// import { FeatureImageSliderComponent } from './components/feature-image-slider/feature-image-slider.component';
// import { ChallengerDropTopTileComponent } from './homepage/sections/challenger-drop-top-tile/challenger-drop-top-tile.component';

// Challenger Wrap
// import { ChallengerWrapComponent } from './challenger-wrap/challenger-wrap.component';
// import { ColorSwatchComponent } from './challenger-wrap/color-swatch/color-swatch.component'
// import { ChallengerWrapTileComponent } from './homepage/sections/challenger-wrap-tile/challenger-wrap-tile.component';
// import { BrotherhoodHeroComponent } from './components/homepage-heroes/brotherhood-hero/brotherhood-hero.component';
// import { BrotherhoodBannerComponent } from './components/banners/brotherhood-banner/brotherhood-banner.component';
import { HPLHeroComponent } from './components/homepage-heroes/horsepower-locator-hero/horsepower-locator-hero.component';

// Horsepower Locator
import { HorsepowerLocatorComponent } from './horsepower-locator/horsepower-locator.component';
import { HPLDealerCardComponent } from './horsepower-locator/page-components/dealer-card/dealer-card.component';
import { HPLSearchFiltersComponent } from './horsepower-locator/page-components/search-filters/search-filters.component';
import { HPLTrimService } from './horsepower-locator/services/trim/trim.service';
import { HPLAllocationService } from './horsepower-locator/services/trim/allocation/allocation.service';
import { HPLSearchTipsComponent } from './horsepower-locator/page-components/search-tips/search-tips.component';
import { HPLDealerAvailabilityComponent } from './horsepower-locator/page-components/dealer-availability/dealer-availability.component';
import { PhoneNumberPipe } from './shared/pipes/phone-number.pipe';
import { AddThisService } from './services/addthis.service';

// Durango Hellcat Banner
import { DurangoHellcatBannerComponent } from './components/banners/durango-hellcat-banner/durango-hellcat-banner.component';
import { LastCallHeroComponent } from './components/homepage-heroes/last-call-hero/last-call-hero.component';
import { VideoSliderComponent } from './components/video-slider/video-slider.component';
import { HorsepowerLocatorTileComponent } from './homepage/sections/horsepower-locator-tile/horsepower-locator-tile.component';

// Demon 170
import { Demon170Component } from './demon-170/demon-170.component';
import { HeroVideoComponent } from './components/hero-video/hero-video.component';
import { ColorSwatchesComponent } from './demon-170/color-swatches/color-swatches.component';
import { InteriorGalleryComponent } from './demon-170/interior-gallery/interior-gallery.component';
import { Demon170OwnerInfoComponent } from './demon-170/owner-info/demon-170-owner-info.component';
import { ProductCard } from './demon-170/owner-info/product-card/product-card.component';

// Demon 170 Homepage Tile
import { Demon170TileComponent } from './homepage/sections/demon-170-tile/demon-170-tile.component';

// Modals
import { ModalCaAllocationComponent } from './components/modals/modal-ca-allocation/modal-ca-allocation.component';

// Countdown Clock
import { CountdownClockComponent } from './components/countdown-clock/countdown-clock.component';

// 20 Days of Horsepower
import { TwentyDaysOfHorsepowerComponent } from './20-days-of-horsepower/20-days-of-horsepower.component';
import { TwentyDaysOfHorsepowerLeaderBoardComponent } from './20-days-of-horsepower/leaderboard/20-days-of-horsepower-leaderboard.component'; 
import { TwentyDaysOfHorsepowerWinnersComponent } from './20-days-of-horsepower/winners/20-days-of-horsepower-winners.component'; 
import { TwentyDaysOfHorsepowerService } from './20-days-of-horsepower/20-days-of-horsepower.service';
import { TableFilterPipe } from './20-days-of-horsepower/leaderboard/pipes/table-filter.pipe';
import { OrderByPipe } from './20-days-of-horsepower/leaderboard/pipes/order-by.pipe';

// Daytona Charger
import { DaytonaChargerComponent } from './components/homepage-heroes/daytona-charger/daytona-charger.component';

// DG EU
import { EuLayoutComponent } from './eu/eu.component';
import { EuComingSoonComponent } from './eu/coming-soon/coming-soon.component';
//8SC
import { EightSecondClubTileComponent } from './homepage/sections/eight-second-club-tile/eight-second-club-tile.component';
// RKN 2024 grudge match hero
import { Rkn2024Component } from './components/homepage-heroes/rkn-2024/rkn-2024.component';

// Directives
import { LazyLoadDirective } from './directives/lazy-load.directive';

import { EventSinglePageComponentTest } from './event/event-single-page-test/event-single-page-test.component';
// Badassador
import { BadassadorHomepageBannerComponent } from './brand-ambassador/components/homepage-banner/homepage-banner.component';
import { BadassadorHeroComponent } from './components/homepage-heroes/badassador-hero/badassador-hero.component';

// Meet Dodge CEO video landing. Temp
import { MeetCeoDodgeComponent } from './meet-ceo-dodge/meet-ceo-dodge.component';

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    GalleryComponent,
    Navigation,
    SafePipe,
    KebabPipe,
    DownloadsComponent,
    //Track Experience
    HpdsComponent,
    // DragRacingComponent,
    FaqComponent,
    AvatarComponent,
    FooterComponent,
    TrademarksComponent,
    ActivationBannerComponent,
    LoginFormInlineComponent,
    HeroPanelComponent,
    SocialBannerComponent,
    BarrettJacksonComponent,
    PasswordResetBannerComponent,
    ChallengerListPageComponent,
    EventSinglePageComponent,
    BarrettJacksonRulesComponent,
    LivestreamComponent,
    DemonFaqComponent,
    ContributorsComponent,
    LivestreamBannerComponent,
    RingtonesComponent,
    RingtoneInstructionsComponent,
    LastChanceComponent,
    ImgCarouselComponent,
    AudioPlayerComponent,
    ActivationNoticeComponent,
    PodcastListPageComponent,
    AuctionBragBookComponent,
    FormatTimePipe,
    LastChanceAuctionBookFullComponent,
    // Contest - Drag Strip Showdown
    DragStripShowdownComponent,
    RulesComponent,
    LeaderboardComponent,
    DragStripShowdownHeroComponent,
    ScheduleComponent,
    LivestreamEmbedComponent,
    NationalLeaderboardPageComponent,
    PaginationComponent,
    EventListLeaderboardsComponent,
    EventSingleLeaderboardComponent,
    StencilsComponent,
    TimeSlipSubmissions,
    UserProfileComponent,
    BadgeComponent,
    RecentArticlesComponent,
    ManageAccountComponent,
    StyleGuideComponent,
    StyleGuideFormComponent,
    StyleGuideGalleryComponent,
    HolidayCardsComponent,
    HolidayCardsComponent,
    Challenger1320Component,
    DeactivateAccountComponent,
    MetamorphosisLegoDodgeComponent,
    SalesIncentivesComponent,
    Dg1320StandingsComponent,
    SpotlightDefaultComponent,
    SurveyComponent,
    PollComponent,
    TrackLocatorComponent,
    TrackInfoWindowComponent,
    JuicerComponent,
    TimeSincePipe,
    NewsArticleSinglePageComponent,
    HorsepowerChallengeComponent,
    GalleryCardComponent,
    GalleryOpenCardComponent,
    GallerySearchComponent,
    BondurantModalComponent,
    VideoPageComponent,
    RacingHqCtaComponent,
    DownloadsCtaComponent,
    BondurantCtaComponent,
    MerchCtaComponent,
    BracketBannerComponent,
    MuscleBehindUsCtaComponent,
    DodgeOfficialCtaComponent,
    DodgeRevealLivetreamBannerComponent,
    //VideoDragPakComponent,
    CarShowBannerComponent,
    DodgeNeverliftBannerComponent,
    // VirtualCarShowComponent,
    // VirtualCarShowListComponent,
    // VirtualCarShowSingleComponent,
    MissionBannerComponent,

    HeroGarageTeaserComponent,

    RoadkillNightsVideo,

    // Drag Pak Experience
    DragPakExperienceComponent,
    DragPakLandingPageComponent,
    DragPakHistoryComponent,
    DragPakBuildOverviewComponent,
    DragPakStartupComponent,
    DragPakCompetitionSetupComponent,
    DragPakPreRunChecklistComponent,
    DragPakInitialTrackTestComponent,
    DragPakECULaunchSettingComponent,
    DragPakPostRunChecklistComponent,
    YoutubeFeedComponent,
    ImageFeedComponent,

    // Advent Calendar
    GarageTeaserTileComponent,
    NeverLiftComponent,

    // Chief Donut Maker
    // ChiefDonutMakerComponent,
    // CDMLandingPageComponent,
    // CDMJobPageComponent,
    // CDMPerksPageComponent,
    // CDMApplicationPageComponent,
    // CDMApplicationStatusPageComponent,
    // CDMExperiencePageComponent,
    // CDMApplicationComponent,
    // CDMHiringProcessComponent,
    // CDMRulesPageComponent,
    // CDMAnnouncementTileComponent,
    // CDMEliminationEpisodeCardComponent,

    EventsSlider,
    FeatureSlider,
    VideosSection,
    VideoTabsComponent,
    ThirteenTwentyCtaComponent,
    DirectConnectionCta,

    // Heroes
    // HeroCDMComponent,
    // CDMFinalistCardComponent,
    EventRegistrationRedirectComponent,
    NeverLiftCountdownComponent,
    PowerbrokersCrateEngineComponent,
    DaytonaChargerComponent,

    // Highway 93
    // HighwayNinetyThreeComponent,
    // HighwayLocationComponent,
    // HighwayMapComponent,
    // HighwaySocialComponent,
    // HighwayFilterComponent,
    // HighwayInfoWindowComponent,
    // HighwayLocationListComponent,
    // HighwayHeroComponent,
    // HighwayJuicerComponent,
    // Hwy93BannerComponent,

    // RKN
    RknLandingPageComponent,
    RKN2023TeaserComponent,
    RknSocialIcons,

    // RKN2024 Hero
    Rkn2024Component,
    
    // RKN2023
    Rkn2023LandingPageComponent,

    // RKN2022
    RKN2022LandingPageComponent,
    RKN2022ContributorsComponent,
    RKNLivestreamCountdownComponent,

    // RKN2021
    Rkn2021LandingPageComponent,
    ContributorOneComponent,
    ContributorTwoComponent,
    ContributorThreeComponent,
    ContributorFourComponent,
    ContributorFiveComponent,

    // SRT Experience
    SRTRegistrationComponent,
    IframeAutoHeightDirective,

    // Challenger Drop Top
    // ChallengerDropTopTileComponent,
    // ChallengerDropTopComponent,
    // FeatureImageSliderComponent,

    // Challenger Wrap
    // ChallengerWrapTileComponent,
    // ChallengerWrapComponent,
    // ColorSwatchComponent,
    // BrotherhoodHeroComponent,
    // BrotherhoodBannerComponent,

    // Horsepower Locator
    HorsepowerLocatorComponent,
    HPLDealerCardComponent,
    HPLSearchFiltersComponent,
    HPLSearchTipsComponent,
    HPLDealerAvailabilityComponent,
    PhoneNumberPipe,
    HPLHeroComponent,
    DurangoHellcatBannerComponent,
    LastCallHeroComponent,
    VideoSliderComponent,
    HorsepowerLocatorTileComponent,

    // Demon 170
    Demon170Component,
    HeroVideoComponent,
    ColorSwatchesComponent,
    InteriorGalleryComponent,
    Demon170OwnerInfoComponent,
    ProductCard,

    // Demon 170 homepage tile
    Demon170TileComponent,

    ModalCaAllocationComponent,
    CountdownClockComponent,

    // Twenty Days of Horsepower
    TwentyDaysOfHorsepowerComponent,
    TwentyDaysOfHorsepowerLeaderBoardComponent,
    TwentyDaysOfHorsepowerWinnersComponent,
    TableFilterPipe,
    OrderByPipe,

    // DG EU
    EuLayoutComponent,
    EuComingSoonComponent,
    //8SC
    EightSecondClubTileComponent,

    // Directives
    LazyLoadDirective,
    EventSinglePageComponentTest,

    // Badassador
    BadassadorHomepageBannerComponent,

    // Badassador
    BadassadorHeroComponent,
    MeetCeoDodgeComponent
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'performance-cars'}),
    RouterModule.forRoot([
      {path: '', component: HomepageComponent, pathMatch: 'full'},
      {path: 'user/activation', component: HomepageComponent},
      {path: 'user/resetPassword', component: HomepageComponent},
      {path: 'user/create-account', component: HomepageComponent},
      {path: 'news', loadChildren: () => import('./news-articles/news-article.module').then(m => m.NewsArticleModule)},
      {
        path: 'news/article/:mainCat/:year/:month/:path',
        component: NewsArticleSinglePageComponent,
        canActivate: [NewsModalGuard]
      },
      {path: 'category/:categoryName', redirectTo: '/news/category/:categoryName'},
      {path: 'article/:mainCat/:year/:month/:path', redirectTo: '/news/article/:mainCat/:year/:month/:path'},
      {path: 'gallery', component: GalleryComponent},
      {path: 'track-locator', component: TrackLocatorComponent},
      {path: 'challenger', component: ChallengerListPageComponent},
      {path: 'racing-hq', loadChildren: () => import('./racing-hq/racing-hq.module').then(m => m.RacingHqModule)},
      {path: 'racing-hq/events', redirectTo: '/events'},
      // {path: 'racing-hq/events', redirectTo: '/events/category/racing'},
      {path: 'category/:categoryName', redirectTo: '/news/category/:categoryName'},
      {path: 'live', component: LivestreamComponent},
      {path: 'barrett-jackson-sweeps', component: BarrettJacksonComponent},
      {path: 'barrett-jackson-sweeps/rules', component: BarrettJacksonRulesComponent},
      {path: 'demon-faq', component: DemonFaqComponent},
      {path: 'contributors', component: ContributorsComponent},
      {path: 'downloads/ringtone-instructions', component: RingtoneInstructionsComponent},
      {path: 'downloads', component: DownloadsComponent},
      // { path: 'carcast', component: PodcastListPageComponent},
      {path: 'track-experience', component: HpdsComponent},
      // { path: 'track-experience/drag-racing', component: DragRacingComponent},
      {path: 'track-experience/faq', component: FaqComponent},
      {path: 'trademarks', component: TrademarksComponent},
      {path: 'ultimate-last-chance', component: LastChanceComponent},
      {path: 'ultimate-last-chance-book', component: AuctionBragBookComponent},
      {path: 'ultimate-last-chance-book-full', component: LastChanceAuctionBookFullComponent},
      {path: 'drag-strip-showdown', redirectTo: '/events'},
      // {path: 'drag-strip-showdown', redirectTo: '/events/category/racing'},
      // {path: 'drag-strip-showdown/create-account', component: DragStripShowdownComponent},
      // {path: 'drag-strip-showdown/login', component: DragStripShowdownComponent},
      // {path: 'drag-strip-showdown/rules', component: RulesComponent},
      // {path: 'drag-strip-showdown/events', component: EventListLeaderboardsComponent},
      // {path: 'drag-strip-showdown/events/:eventId/:name', component: EventSingleLeaderboardComponent},
      // {path: 'drag-strip-showdown/events/:eventId/:name/page/:page', component: EventSingleLeaderboardComponent},
      // {path: 'drag-strip-showdown/national-leaderboard', component: NationalLeaderboardPageComponent},
      // {path: 'drag-strip-showdown/national-leaderboard/page/:page', component: NationalLeaderboardPageComponent},
      {
        path: 'user/:username/vehicle/:vehicleId', component: UserProfileComponent,
        resolve: {
          user: UserProfileResolverService
        }
      },
      {
        path: 'user/:username', component: UserProfileComponent,
        resolve: {
          user: UserProfileResolverService
        }
      },
      {path: 'manage-account', component: ManageAccountComponent, canActivate: [AuthGuard]},
      {path: 'style-guide', component: StyleGuideComponent},
      {path: 'owner-perks/1320', redirectTo: '', pathMatch: 'full'},
      {path: 'owner-perks/nhra-nmca-memberships', redirectTo: '', pathMatch: 'full'},
      {path: 'survey', component: SurveyComponent},
      {path: 'videos', component: VideoPageComponent},
      {path: 'videos/:videoId', component: VideoPageComponent},
      {path: 'events', loadChildren: () => import('./event/event.module').then(m => m.EventModule)},
      {path: 'events/:id/:name', component: EventSinglePageComponentTest},
      {
        path: '1320-club',
        loadChildren: () => import('./dg-1320/thirteen-twenty.module').then(m => m.ThirteenTwentyModule)
      },
      {
        path: 'bracket',
        redirectTo: '/news/article/showcase/2020/06/dodgegarage-bracket-of-brotherhood-and-the-winner-is.html'
      },
      {path: 'drag-pak', loadChildren: () => import('./drag-pak/drag-pak.module').then(m => m.DragPakModule)},
      {path: 'virtual-car-show', redirectTo: '/'},
      {path: 'virtual-car-show/archive', redirectTo: '/'},
      {path: 'virtual-car-show/archive/:slug', redirectTo: '/'},
      {path: 'roadkill-nights-video', component: RoadkillNightsVideo},
      // Drag Pak Experience
      {path: 'drag-pak-experience', component: DragPakExperienceComponent},
      {path: 'drag-pak-experience/:page', component: DragPakExperienceComponent},

      // Advent Calendar
      {path: 'never-lift', component: NeverLiftComponent},
      // Chief Donut Maker
      {path: 'chief-donut-maker', redirectTo: '/'},
      {path: 'chief-donut-maker/:page', redirectTo: '/'},
      // Event Redirects
      {path: 'onsite-event-registration', component: EventRegistrationRedirectComponent},
      // Highway 93
      {path: 'hwy93', redirectTo: '', pathMatch:'prefix'},
      
      // RKN
      {path: 'roadkill-nights', component: RknLandingPageComponent},
      {path: 'roadkill-nights-2021', component: Rkn2021LandingPageComponent},
      {path: 'roadkill-nights-2021/throtl', component: ContributorOneComponent},
      {path: 'roadkill-nights-2021/tavarish', component: ContributorTwoComponent},
      {path: 'roadkill-nights-2021/eric-malone', component: ContributorThreeComponent},
      {path: 'roadkill-nights-2021/alex-taylor', component: ContributorFourComponent},
      {path: 'roadkill-nights-2021/westen-champlin', component: ContributorFiveComponent},
      {path: 'roadkill-nights-2022', component: RKN2022LandingPageComponent},
      {path: 'roadkill-nights-2022/:slug', component: RKN2022ContributorsComponent},
      {path: 'roadkill-nights-2023', component: Rkn2023LandingPageComponent},

      // SRT experience
      {path: 'srt', component: SRTRegistrationComponent},
      // Challenger Drop Top
      {path: 'droptop-challenger', redirectTo: '/'},
      // Challenger Wrap
      {path: 'challenger-wrap', redirectTo: '/'},
      // Horsepower Locator
      {path: 'horsepower-locator', component: HorsepowerLocatorComponent},
      // Demon 170
      {path: 'srt-demon-170', component: Demon170Component},
      {path: 'demon-170-owner-info', component: Demon170OwnerInfoComponent},
      
      
      {path: 'meet-ceo-dodge', component: MeetCeoDodgeComponent},
      
      
      // Dodge Days of Horsepower
      {path: 'dodge-days-of-horsepower', component: TwentyDaysOfHorsepowerComponent},
      {path: '20-days-of-horsepower', redirectTo: '/dodge-days-of-horsepower'},

      // Redirect for 8-second club
      {path: '8-second-club', redirectTo: '/1320-club/8-seconds'},
      // DG EU
      {
        path: 'eu',
        component: EuComingSoonComponent
      },
      // Brand Ambassador
      { path: 'badassador', loadChildren: () => import('./brand-ambassador/brand-ambassador.module').then(m => m.BrandAmbassadorPageModule) },
      // History timeline
      { path: 'history', loadChildren: () => import('./history/history.module').then(m => m.HistoryPageModule) },

      {
        path: '**',
        loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule)
      },
    ], {
      initialNavigation: 'enabled',
      enableTracing: false,
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
      scrollOffset: [0, 90], // [x, y]
      anchorScrolling: 'enabled'
    }),
    FormsModule,
    HttpClientModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    BsDatepickerModule.forRoot(),
    NgxMasonryModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    DeferLoadModule,
    NewsArticleSharedModule,
    CtaTilesSharedModule,
    VideoSharedModule,
    SharedModule,
    EventSharedModule,
    ThirteenTwentySharedModule,
    UserSharedModule,
    CommentSharedModule,
    NgxJsonLdModule,
    RknModule,
    CountdownModule,
    SwiperModule
  ],
  providers: [
    ChallengerService,
    EventService,
    UserService,
    HelperService,
    GalleryService,
    CanActivateViaAuthGuard,
    ContestService,
    ContributorsService,
    EventDodgeOfficialService,
    PodcastService,
    NewsArticleService,
    DownloadsService,
    GalleryServiceService,
    RaceService,
    TimeSlipService,
    TimeSlipHelper,
    VehicleService,
    UserProfileResolverService,
    BadgeService,
    CommentService,
    PollService,
    TrackLocatorService,
    JuicerService,
    AuthGuard,
    DealerLocatorService,
    // Trim Service for Horsepower Locator
    HPLTrimService,
    HPLAllocationService,
    // VirtualCarShowResolverService,
    // VirtualCarShowService,
    CookieService,
    // HighwayLocationsService,
    AddThisService,
    TwentyDaysOfHorsepowerService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UserInterceptor,
      multi: true
    }
  ],
  entryComponents: [TrackInfoWindowComponent],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
