import { Component, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { GtmService } from '../../../services/gtm-tracking.service';

@Component({
  selector: 'badassador-hero',
  templateUrl: './badassador-hero.component.html',
  styleUrls: ['./badassador-hero.component.scss']
})
export class BadassadorHeroComponent implements OnInit {

  constructor(
    private gtmService: GtmService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit() {}
}
