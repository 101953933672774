import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Poll} from "./poll";
//import {Answer} from "./answer";
import {UserModel} from "../user/user";

@Injectable()
export class PollService {

  constructor(
    private http: HttpClient
  ) { }

  getPoll(id: number): Observable<any> {
    let url = environment.url + '/rest/poll/' + id;
    return this.http.get<any>(url);
  }

  submitVote(poll: Poll, answerId: number, user: UserModel): Observable<any> {
    let url = environment.url + '/rest/poll/' + poll.id + '/vote';
    let params = new FormData;
    params.append('answerId', answerId.toString());
    params.append('userId', user.id.toString());
    return this.http.post<any>(url, params);
  }

}
