<a class="incentive-container" href="https://www.dodge.com/vehicle-selector.incentives.html?app=incentives" target="_blank" (click)="helperService.trackGAEvent('Outbound', 'Click', 'Incentives and Offers')" >
  <div class="banner-logo">
    <img loading="lazy" src="/assets/images/sales-incentives/view-current-offers.svg" alt="Power Dollars">
  </div>
  <div class="tablet-line-break"></div>
  <div class="vehicle" data-vehicle="charger">
    <picture>
      <source srcset="/assets/images/sales-incentives/charger-daytona.webp" type="image/webp">
      <source srcset="/assets/images/sales-incentives/charger-daytona.png" type="image/png">
      <img loading="lazy" src="/assets/images/sales-incentives/charger-daytona.png" alt="Charger">
    </picture>
    <span class="text-uppercase">Charger Daytona</span>
  </div>
  <div class="vehicle" data-vehicle="durango">
    <picture>
      <source srcset="/assets/images/sales-incentives/durango.webp" type="image/webp">
      <source srcset="/assets/images/sales-incentives/durango.png" type="image/png">
      <img loading="lazy" src="/assets/images/sales-incentives/durango.png" alt="Durango">
    </picture>
    <span class="text-uppercase">Durango</span>
  </div>
  <div class="vehicle" data-vehicle="hornet">
    <picture>
      <source srcset="/assets/images/sales-incentives/hornet.webp" type="image/webp">
      <source srcset="/assets/images/sales-incentives/hornet.png" type="image/png">
      <img loading="lazy" src="/assets/images/sales-incentives/hornet.png" alt="Hornet">
    </picture>
    <span class="text-uppercase">Hornet</span>
  </div>
</a>
