<footer id="global-footer" class="footer relative">
  <div class="site-wrapper-xl">
    <div class="row">
      <div class="col-sm-12 col-md-7 col-lg-6 copyright">
        <a class="footer-logo ga-event" routerLink="/" data-ga_category="Nav" data-ga_action="Footer" data-ga_label="Footer Logo" (click)="trackLogoClicks()">
          <img loading="lazy" class="img-responsive" src="/assets/images/logos/dodge-garage-logo.png" alt="Dodge Garage - Logo" width="200" height="45">
        </a>
        <div class="content-container">
          <p>
            &copy;{{ copyrightYear }} FCA US LLC. All Rights Reserved. Chrysler, Dodge, Jeep, Ram, Wagoneer, Mopar and SRT are registered trademarks of FCA US LLC. ALFA ROMEO and FIAT are registered trademarks of FCA US LLC Group Marketing S.p.A., used with permission.
          </p>
          <p>
            FCA strives to ensure that its websites are accessible to individuals with disabilities. Should you encounter an issue accessing any content on DodgeGarage.com, please contact us at <a href="mailto:customerservice@dodgegarage.com">customerservice@dodgegarage.com </a> for further assistance or to report a problem. Access to DodgeGarage.com is subject to FCA's Privacy Policy and Terms of Use.
          </p>
          <div class="content-links">
            <a class="legal-footer-link ga-event" data-dest="Copyright" href="https://www.dodge.com/crossbrand_us/copyright.html" target="_blank" data-ga_category="Nav" data-ga_action="Footer" data-ga_label="Copyright" rel="noopener" (click)="trackDisclaimerClicks('copyright')">Copyright</a> |
            <a class="legal-footer-link ga-event" data-dest="Privacy Statement" href="https://www.dodge.com/crossbrand_us/privacy.html" target="_blank" data-ga_category="Nav" data-ga_action="Footer" data-ga_label="Privacy Statement" rel="noopener" (click)="trackDisclaimerClicks('privacy-statement')">Privacy Statement</a> |
            <a class="legal-footer-link ga-event" data-dest="Terms of Use" href="https://www.dodge.com/crossbrand_us/terms-of-use.html" target="_blank" data-ga_category="Nav" data-ga_action="Footer" data-ga_label="Terms of Use" rel="noopener" (click)="trackDisclaimerClicks('terms-of-use')">Terms of Use</a> |
            <a class="legal-footer-link ga-event" data-dest="Legal" routerLink="/trademarks" target="_blank" data-ga_category="Nav" data-ga_action="Footer" data-ga_label="Legal" rel="noopener" (click)="trackDisclaimerClicks('legal-safety-trademark-information')">Legal, safety and trademark information</a> |
            <a class="legal-footer-link ga-event" data-dest="Accessibility" href="https://fcacommunity.force.com/Dodge/s/article/What-steps-do-you-take-to-ensure-website-accessibility" target="_blank" data-ga_category="Nav" data-ga_action="Footer" data-ga_label="Accessibility" rel="noopener" (click)="trackDisclaimerClicks('accessibility')">Accessibility</a> |
            <a class="legal-footer-link ga-event" data-dest="Manage Your Privacy Choices" href="https://privacyportal-cdn.onetrust.com/dsarwebform/abdee64f-f547-46bd-97a7-f56d58479fce/1c3a169b-1230-4a2e-b664-8785e3763e2c.html" target="_blank" data-ga_category="Nav" data-ga_action="Footer" data-ga_label="Manage Your Privacy Choices" rel="noopener" (click)="trackDisclaimerClicks('manage-your-privacy-choices')">Manage Your Privacy Choices</a> |
            <a class="legal-footer-link ga-event" data-dest="Cookie Settings" href="javascript:Optanon.ToggleInfoDisplay();" data-ga_category="Nav" data-ga_action="Footer" data-ga_label="Cookie Settings" (click)="trackDisclaimerClicks('cookie-settings')">Cookie Settings</a> |
            <a class="legal-footer-link ga-event" data-dest="Cookie Settings" href="https://www.mopar.com/en-us/my-vehicle/recalls/search.html" target="_blank" data-ga_category="Nav" data-ga_action="Footer" data-ga_label="Recall Information" (click)="trackDisclaimerClicks('recall-information')">Recall Information</a>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-5 col-lg-offset-1 quick-links">
        <div class="row">
          <div class="col-sm-6 col-md-6 col-lg-5 link-wrap">
            <p class="footer-heading">Quick Links</p>
            <ul class="list-unstyled quick-link-container">
              <li><a class="ga-event" data-dest="News" routerLink="/news" data-ga_category="Nav" data-ga_action="Footer" data-ga_label="News" (click)="trackQuickLinkClicks('news')">News</a></li>
              <li><a class="ga-event" data-dest="Racing HQ" routerLink="/racing-hq" data-ga_category="Nav" data-ga_action="Footer" data-ga_label="Racing HQ" (click)="trackQuickLinkClicks('racing-hq')">Racing HQ</a></li>
              <li><a class="ga-event" data-dest="Events" routerLink="/events" data-ga_category="Nav" data-ga_action="Footer" data-ga_label="Events" aria-label="Dodge Garage Events" (click)="trackQuickLinkClicks('events')">Events</a></li>
              <li><a class="ga-event" data-dest="Merch" href="https://www.life.dodge.com/ " target="_blank" data-ga_category="Nav" data-ga_action="Footer" data-ga_label="Merch" rel="noopener" (click)="trackQuickLinkClicks('merch')">Merch</a></li>
              <li><a class="ga-event" data-dest="Media" routerLink="/videos" data-ga_category="Nav" data-ga_action="Footer" data-ga_label="Media" (click)="trackQuickLinkClicks('media')">Media</a></li>
            </ul>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-7 sign-up" *ngIf="!userService.userModel.isLoggedIn">
            <p class="footer-heading">Sign Up to Join In</p>
            <p>Enter your email &amp; create a DodgeGarage account for exclusive first looks, promotions and more.</p>
            <form id="footer-signup" (submit)="signUpModal($event, $event.target[0].value); footerGASignUp($event)">
              <div class="form-group inline-combo">
                <input type="email" class="form-control" name="footerEmail" placeholder="Enter Email Address" />
                <input type="submit" class="btn btn-primary" value="submit" />
              </div>
            </form>
          </div>
        </div>
      </div>


      <!-- <hr class="visible-xs">
      <a class="col-lg-2 sign-up-save" data-dest="Sign Up and Save" (click)="signUpModal($event); footerGASignUp($event)" data-ga_category="Footer Navigation" data-ga_action="Footer" data-ga_label="Sign Up">
        <img loading="lazy" src="/assets/images/sign-up-save.png" alt="Sign up and Save" class="img-responsive">
      </a> -->
    </div>
  </div>
  <div class="site-wrapper-xl">
    <div class="row row-lg-flex">
      <div class="col-sm-12 col-md-6 col-xl-8 brand-links">
        <ul class="list-unstyled brand-link-list" id="b-footer">
          <li><a class="brand-footer-link ga-event" data-dest="Chrysler" href="https://www.chrysler.com/" target="_blank" data-ga_category="Nav" data-ga_action="Footer" data-ga_label="Chrysler" rel="noopener" (click)="trackBrandLinksClicks('chrysler-t1')">Chrysler</a></li>
          <li><a class="brand-footer-link ga-event" data-dest="Dodge" href="https://www.dodge.com/" target="_blank" data-ga_category="Nav" data-ga_action="Footer" data-ga_label="Dodge" rel="noopener" (click)="trackBrandLinksClicks('dodge-t1')">Dodge</a></li>
          <li><a class="brand-footer-link ga-event" data-dest="Jeep" href="https://www.jeep.com/" target="_blank" data-ga_category="Nav" data-ga_action="Footer" data-ga_label="Jeep" rel="noopener" (click)="trackBrandLinksClicks('jeep-t1')">Jeep</a><sub class="realign subscript">&reg;</sub></li>
          <li><a class="brand-footer-link ga-event" data-dest="Ram" href="https://www.ramtrucks.com/" target="_blank" data-ga_category="Nav" data-ga_action="Footer" data-ga_label="Ram" rel="noopener" (click)="trackBrandLinksClicks('ram-t1')">Ram</a></li>
          <li><a class="brand-footer-link ga-event" data-dest="Mopar" href="http://www.mopar.com/" target="_blank" data-ga_category="Nav" data-ga_action="Footer" data-ga_label="Mopar" rel="noopener" (click)="trackBrandLinksClicks('mopar')">Mopar</a><sub class="realign subscript">&reg;</sub></li>
          <li><a class="brand-footer-link ga-event" data-dest="Fiat" href="https://www.fiatusa.com/" target="_blank" data-ga_category="Nav" data-ga_action="Footer" data-ga_label="Fiat" rel="noopener" (click)="trackBrandLinksClicks('fiat-t1')">Fiat</a><sup class="realign superscript">&reg;</sup></li>
          <li><a class="brand-footer-link ga-event"  href="https://www.alfaromeousa.com/" target="_blank" data-ga_category="Nav" data-ga_action="Footer" data-ga_label="Alfa Romeo" rel="noopener" (click)="trackBrandLinksClicks('alfa-t1')">Alfa Romeo</a></li>
          <li><a class="brand-footer-link ga-event" data-dest="FCA Fleet" href="https://www.fcausfleet.com/" target="_blank" data-ga_category="Nav" data-ga_action="Footer" data-ga_label="FCA Fleet" rel="noopener" (click)="trackBrandLinksClicks('fleet')">FCA Fleet</a></li>
        </ul>
      </div>
      <div class="col-sm-12 col-md-3 col-lg-2 partner-logos">
        <a class="ga-event" href="https://www.dcperformance.com/" target="_blank" data-ga_category="Nav" data-ga_action="Footer" data-ga_label="Direct Connection" rel="noopener" (click)="trackBrandLinksClicks('direct-connection-logo')"><img src="/assets/images/logos/direct-connection-logo.png" alt="Direct Connection - Logo" width="270" height="55"></a>
        <a class="ga-event driveability" href="https://www.stellantisdriveability.com/" target="_blank" data-ga_category="Nav" data-ga_action="Footer" data-ga_label="Driveability" rel="noopener" (click)="trackBrandLinksClicks('stellantis-driveability-logo')"><img src="/assets/images/logos/driveability-logo.png" alt="FCA US Driveability - Logo" width="370" height="62"></a>
      </div>
    </div>
  </div>
</footer>

<!-- User Modal -->
<ng-template #userModal>
  <div class="modal-container">
    <div class="modal-header">
      <button class="modal-close" (click)="userModalRef.hide()"><i class="fa fa-close"><span class="sr-only">close</span></i></button>
    </div>
    <div class="modal-body site-wrapper">
      <div class="signup-form form-1320">
        <dg-user-onboarding class="step-form" [defaultTab]="'register'" (userLoggedIn)="closeUserModal()"></dg-user-onboarding>
      </div>
    </div>
  </div>
</ng-template>
