// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  url: 'https://test.api.dodgegarage.com',
  newsApiUrl: 'https://test.api.dodgegarage.com/news/wp-json/wp/v2/',
  newsSearchApiUrl: 'https://test.api.dodgegarage.com/news/wp-json/relevanssi/v1/',
  discourseApiUrl: 'https://test.api.dodgegarage.com/rest/discourse/',
  discourseStartDate: '2020-06-06T00:00:00',
  vimeoAPiKey: '54a7e85453fdd28cc24cded97034f78e',
  dealerLocatorUrl: 'https://dev.dealerlocator.io/fca-dealer-locator',
  chiefDonutMakerApiUrl: 'https://test.api.dodgegarage.com',
  testHPLApiUrl: 'https://test.api.dodgegarage.com',

  // Drag Pak
  dragPakApiUrl: 'https://test.api.dodgegarage.com/dragpak-api',
  dragPakDiscourseIds: {
    'drag-pak' : 122,
    'history' :  113,
    'build-overview':  115,
    'startup-procedures':  116,
    'competition-setup':  117,
    'pre-run-checklist':  118,
    'initial-track-test':  119,
    'ecu-launch-setting':  120,
    'post-run-checklist':  121
  },
  cdmTopTenDiscourseCategoryId: 12,
  cdmTopTenDiscourseId: 126,

  // RKN
  rknYouTubeKey: 'AIzaSyDVerfgymxk9htKS07-Vppsk8jgseH9BL4',
  rknWPKey: 'oiiMzzAGxlhmk7Fc286dRoWFYq430KUp3dcCMNjk',
  rknWpURL: 'https://test.api.dodgegarage.com/news/wp-json/'
};
