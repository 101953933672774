import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

@Component({
  selector: 'dg-horsepower-locator-tile',
  templateUrl: './horsepower-locator-tile.component.html',
  styleUrls: ['./horsepower-locator-tile.component.less']
})
export class HorsepowerLocatorTileComponent implements OnInit {

  // Tooltip Modal
  @ViewChild('tooltipModal', { static: true }) public tooltipModal: TemplateRef<any>;
  tooltipModalRef: BsModalRef;
  activeTooltip: number;

  constructor(private modalService: BsModalService) { }

  ngOnInit(): void {

  }

  sendGTMEvent(): void {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'hpl.homepage-banner.click',
      eventCategory: 'Hero',
      eventAction: 'Click',
      eventLabel: 'HPL Homepage Hero Banner'
    });
  }

  // tooltip modal functionlaity
  openTooltipModal(id: number) {
    this.activeTooltip = id;
    this.tooltipModalRef = this.modalService.show(this.tooltipModal, Object.assign({}, { class: 'modal-card' }));
  }

}
